import React, { useEffect, useContext, useState } from 'react';
import { AppSettings } from '../../config/app-settings.js';
import { Icon } from '@iconify/react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MessageContainer from '../../ownComponents/ChatContainer/messageContainer.js';
import Chat from '../../ownComponents/ChatContainer/chat.js';
import { v4 as uuidv4 } from 'uuid';
import EmojiPickerComponent from '../../ownComponents/emojiPicker/index.js';
import { getLocalStorageItem } from "../../helpers/localStorage";
import { getToken } from "../../helpers/token";
import { urlAPIMultiAgent } from "../../helpers/urlAPI";
import fetchAPI from "../../helpers/fetch";

function Conversations() {
	const token = getToken();
	const { suscriptionId } = JSON.parse(getLocalStorageItem("suscription"));
	const context = useContext(AppSettings);
	const [isMobileSidebarToggled, setIsMobileSidebarToggled] = useState(false);
	const [selectedChat, setSelectedChat] = useState({
		id: 0,
		agent: "",
		client: "",
		startDate: "",
		endDate: "",
		active: false,
		waitingForAgent: false,
		waitingForFile: null,
		startedByAgent: false,
		finishedByAgent: null
	});

	const [textArea, setTextArea] = useState("");


	const handleEmojiSelect = (emojiObject) => {
		setTextArea(textArea + emojiObject.emoji)
	};
	const [dataConversations, setDataConversations] = useState([]);
	const [dataConversation, setDataConversation] = useState([]);
	// const [isLoading, setIsLoading] = useState({
	// 	load: false,
	// 	dataId: 1
	// });

	// const objInfoDemo = [
	// 	{
	// 		id: 1,
	// 		title: "Reunión de Proyecto",
	// 		text: "Discutimos los avances del proyecto y los siguientes pasos.",
	// 		image: "https://picsum.photos/id/1/400/300",
	// 		dateTime: "2024-08-27T10:30:00"
	// 	},
	// 	{
	// 		id: 2,
	// 		title: "Actualización de Producto",
	// 		text: "Se lanzó la nueva versión del producto con mejoras significativas.",
	// 		image: "https://picsum.photos/id/10/400/300",
	// 		dateTime: "2024-08-26T14:00:00"
	// 	},
	// 	{
	// 		id: 3,
	// 		title: "Revisión de Código",
	// 		text: "Se revisaron las últimas solicitudes de extracción.",
	// 		image: "https://picsum.photos/id/100/400/300",
	// 		dateTime: "2024-08-25T09:15:00"
	// 	},
	// 	{
	// 		id: 4,
	// 		title: "Lanzamiento de Campaña",
	// 		text: "La nueva campaña de marketing se lanzará la próxima semana.",
	// 		image: "https://picsum.photos/id/1000/400/300",
	// 		dateTime: "2024-08-24T11:45:00"
	// 	},
	// 	{
	// 		id: 5,
	// 		title: "Encuesta de Satisfacción",
	// 		text: "Se enviaron encuestas a los clientes para evaluar la satisfacción.",
	// 		image: "https://picsum.photos/id/1001/400/300",
	// 		dateTime: "2024-08-23T16:30:00"
	// 	},
	// 	{
	// 		id: 6,
	// 		title: "Taller de Capacitación",
	// 		text: "Se realizó un taller sobre nuevas tecnologías en la oficina.",
	// 		image: "https://picsum.photos/id/1002/400/300",
	// 		dateTime: "2024-08-22T13:00:00"
	// 	},
	// 	{
	// 		id: 7,
	// 		title: "Reunión de Estrategia",
	// 		text: "Definimos la estrategia para el próximo trimestre.",
	// 		image: "https://picsum.photos/id/1003/400/300",
	// 		dateTime: "2024-08-21T15:00:00"
	// 	},
	// 	{
	// 		id: 8,
	// 		title: "Feedback del Cliente",
	// 		text: "Recibimos comentarios positivos sobre el servicio al cliente.",
	// 		image: "https://picsum.photos/id/1004/400/300",
	// 		dateTime: "2024-08-20T10:00:00"
	// 	},
	// 	{
	// 		id: 9,
	// 		title: "Planificación de Eventos",
	// 		text: "Estamos organizando un evento para el lanzamiento del nuevo producto.",
	// 		image: "https://picsum.photos/id/1005/400/300",
	// 		dateTime: "2024-08-19T12:00:00"
	// 	},
	// 	{
	// 		id: 10,
	// 		title: "Reunión de Equipo",
	// 		text: "El equipo se reunió para discutir los objetivos del mes.",
	// 		image: "https://picsum.photos/id/1006/400/300",
	// 		dateTime: "2024-08-18T09:00:00"
	// 	},
	// 	{
	// 		id: 11,
	// 		title: "Análisis de Datos",
	// 		text: "Se realizó un análisis de los datos de ventas del último trimestre.",
	// 		image: "https://picsum.photos/id/1012/400/300",
	// 		dateTime: "2024-08-17T14:30:00"
	// 	},
	// 	{
	// 		id: 12,
	// 		title: "Revisión de Proyectos",
	// 		text: "Se revisaron los proyectos en curso y su progreso.",
	// 		image: "https://picsum.photos/id/1008/400/300",
	// 		dateTime: "2024-08-16T11:00:00"
	// 	},
	// 	{
	// 		id: 13,
	// 		title: "Sesión de Brainstorming",
	// 		text: "Generamos ideas para nuevos productos en la sesión de brainstorming.",
	// 		image: "https://picsum.photos/id/1009/400/300",
	// 		dateTime: "2024-08-15T13:45:00"
	// 	},
	// 	{
	// 		id: 14,
	// 		title: "Actualización de Seguridad",
	// 		text: "Se implementaron nuevas medidas de seguridad en la aplicación.",
	// 		image: "https://picsum.photos/id/1010/400/300",
	// 		dateTime: "2024-08-14T10:15:00"
	// 	},
	// 	{
	// 		id: 15,
	// 		title: "Cierre de Proyecto",
	// 		text: "Se cerró el proyecto exitosamente y se entregaron los resultados.",
	// 		image: "https://picsum.photos/id/1011/400/300",
	// 		dateTime: "2024-08-13T16:00:00"
	// 	}
	// ];

	// const [dataInfoDemo, setDataInfoDemo] = useState(objInfoDemo);
	const [dataFilterInfoDemo, setDataFilterInfoDemo] = useState([]);

	const handleMobileSidebarToggle = () => {
		setIsMobileSidebarToggled(!isMobileSidebarToggled);
	};

	const handleSearch = (event) => {
		const { target: { value } } = event;

		if (event.key === 'Enter') {
			console.log({ dataConversations })
			const result = dataConversations.filter(item => item.agent.toLowerCase().includes(value.toLowerCase()))
			setDataFilterInfoDemo(result);
		}
	}


	// const objConversation = [
	// 	{
	// 		text: "¡Hola! ¿Cómo estás?",
	// 		image: "https://example.com/images/user1.png",
	// 		dateTime: "2024-08-28T09:00:00Z",
	// 		isStart: true
	// 	},
	// 	{
	// 		text: "¡Hola! Estoy bien, gracias. ¿Y tú?",
	// 		image: "https://example.com/images/user2.png",
	// 		dateTime: "2024-08-28T09:01:00Z",
	// 		isStart: false
	// 	},
	// 	{
	// 		text: "Todo bien, solo un poco ocupado con el trabajo.",
	// 		image: "https://example.com/images/user1.png",
	// 		dateTime: "2024-08-28T09:02:00Z",
	// 		isStart: true
	// 	},
	// 	{
	// 		text: "Entiendo, a veces es difícil encontrar tiempo para uno mismo.",
	// 		image: "https://example.com/images/user2.png",
	// 		dateTime: "2024-08-28T09:03:00Z",
	// 		isStart: false
	// 	},
	// 	{
	// 		text: "Sí, pero estoy tratando de organizarme mejor.",
	// 		image: "https://example.com/images/user1.png",
	// 		dateTime: "2024-08-28T09:04:00Z",
	// 		isStart: true
	// 	},
	// 	{
	// 		text: "Eso es genial. ¿Tienes algún plan para el fin de semana?",
	// 		image: "https://example.com/images/user2.png",
	// 		dateTime: "2024-08-28T09:05:00Z",
	// 		isStart: false
	// 	},
	// 	{
	// 		text: "Sí, planeo salir a caminar y disfrutar del aire libre.",
	// 		image: "https://example.com/images/user1.png",
	// 		dateTime: "2024-08-28T09:06:00Z",
	// 		isStart: true
	// 	},
	// 	{
	// 		text: "¡Eso suena genial! Espero que tengas un buen tiempo.",
	// 		image: "https://example.com/images/user2.png",
	// 		dateTime: "2024-08-28T09:07:00Z",
	// 		isStart: false
	// 	}
	// ];
	useEffect(() => {
		// context.handleSetAppSidebarMinified(true);
		// context.handleSetAppHeaderInverse(true);
		context.handleSetAppContentFullHeight(true);
		context.handleSetAppContentClass('p-0');

		return () => {
			context.handleSetAppSidebarMinified(false);
			context.handleSetAppHeaderInverse(false);
			context.handleSetAppContentFullHeight(false);
			context.handleSetAppContentClass('');
		};

		// eslint-disable-next-line
	}, []);

	useEffect(() => {

		setSelectedChat({
			...selectedChat, client: dataConversations[0]?.agent
		})

		return () => {
			setSelectedChat({
				title: "", text: "", image: "", dateTime: "", id: false
			})
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {

		const fetchConversations = async () => {
			const headers = {
				'Authorization': `Bearer ${token}`
			}
			const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/conversation/list`, 'POST', null, headers);
			// console.log("conversations:", result.data);
			setDataConversations(result.data);
			setDataFilterInfoDemo(result.data);
		}

		fetchConversations();

		// eslint-disable-next-line
	}, []);

	useEffect(() => {

		if (selectedChat.id === 0) {
			return;
		}
		const fetchConversation = async () => {
			const headers = {
				'Authorization': `Bearer ${token}`
			}
			const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/conversation/${selectedChat.id}/message/list`, 'POST', null, headers);
			console.log("conversation:", result.data);
			setDataConversation(result.data);
		}

		fetchConversation();

		// eslint-disable-next-line
	}, [selectedChat.id]);

	return (
		<div className={'messenger ' + (isMobileSidebarToggled ? 'messenger-chat-content-mobile-toggled' : '')} id="messenger">
			<div className="messenger-menu">
				<div className="messenger-menu-item my-2">
					<a href="#/" className="messenger-menu-link">
						<div className="m-n1">
							<img alt="" src="/assets/img/user/user-13.jpg" className="w-100 d-block rounded-circle" />
						</div>
					</a>
				</div>
				<div className="messenger-menu-item active">
					<a href="#/" className="messenger-menu-link">
						<Icon className="iconify fs-30px" icon="solar:dialog-2-bold-duotone" />
					</a>
				</div>
				<div className="messenger-menu-item">
					<a href="#/" className="messenger-menu-link">
						<Icon className="iconify fs-30px" icon="solar:notebook-bold-duotone" />
					</a>
				</div>
				<div className="messenger-menu-item">
					<a href="#/" className="messenger-menu-link">
						<Icon className="iconify fs-30px" icon="solar:box-minimalistic-bold-duotone" />
					</a>
				</div>
				<div className="messenger-menu-item">
					<a href="#/" className="messenger-menu-link">
						<Icon className="iconify fs-30px" icon="solar:folder-with-files-bold-duotone" />
					</a>
				</div>
				<div className="messenger-menu-item">
					<a href="#/" className="messenger-menu-link">
						<Icon className="iconify fs-30px" icon="solar:clapperboard-play-bold-duotone" />
					</a>
				</div>
				<div className="messenger-menu-item">
					<a href="#/" className="messenger-menu-link">
						<Icon className="iconify fs-30px" icon="solar:settings-bold-duotone" />
					</a>
				</div>
			</div>
			<div className="messenger-chat">
				<div className="messenger-chat-header d-flex">
					<div className="flex-1 position-relative">
						<input type="text" className="form-control border-0 bg-light ps-30px" placeholder="Buscar" onKeyUp={handleSearch} />
						<i className="fa fa-search position-absolute start-0 top-0 h-100 ps-2 ms-3px d-flex align-items-center justify-content-center"></i>
					</div>
					<div className="ps-2">
						<a href="#/" className="btn border-0 bg-light shadow-none">
							<i className="fa fa-plus"></i>
						</a>
					</div>
				</div>
				<div className="messenger-chat-body">
					<PerfectScrollbar className="h-100">
						{
							dataFilterInfoDemo.map((data) => (
								<MessageContainer conversation={data} isActive={selectedChat.id === data.id} ftClick={() => {
									setSelectedChat(data);
									// setIsLoading(isLoading => ({ load: !isLoading.load, dataId: data.id })
									// )
								}}
									key={data.id} />
							))
						}
					</PerfectScrollbar>
				</div>
			</div>
			<div className="messenger-content">
				<div className="widget-chat">
					<div className="widget-chat-header">
						<div className="d-block d-lg-none">
							<button type="button" className="btn border-0 shadow-none" onClick={handleMobileSidebarToggle}>
								<i className="fa fa-chevron-left fa-lg"></i>
							</button>
						</div>
						<div className="widget-chat-header-content">
							<div className="fs-5 fw-bold">{selectedChat.title}</div>
						</div>
						<div className="">
							<button type="button" className="btn border-0 shadow-none" data-bs-toggle="dropdown">
								<i className="fa fa-ellipsis fa-lg"></i>
							</button>
							<ul className="dropdown-menu">
								<li>
									<a className="dropdown-item" href="#/">Action</a>
								</li>
								<li>
									<a className="dropdown-item" href="#/">Another action</a>
								</li>
								<li>
									<a className="dropdown-item" href="#/">Something else here</a>
								</li>
							</ul>
						</div>
					</div>
					{/* <PerfectScrollbar className="widget-chat-body h-100">
						<div className="widget-chat-item with-media end">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-13.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-message"> Good morning, team! Just a reminder, we need to prepare the project report by Friday. Let's stay on track and meet our deadlines. </div>
									<div className="widget-chat-time">08:45AM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-1.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-blue">Daniel</div>
									<div className="widget-chat-message"> Morning! I'm on it and will start compiling the data today. </div>
									<div className="widget-chat-time">09:02AM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-2.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-indigo">Mark</div>
									<div className="widget-chat-message"> Thanks for the heads up! I'll make sure the design elements are ready for the report. </div>
									<div className="widget-chat-time">09:20AM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-3.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-red">Alexander</div>
									<div className="widget-chat-message"> Got it. I'll review the financial data and ensure all the numbers are accurate. </div>
									<div className="widget-chat-time">09:35AM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media end">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-13.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-message"> Great! Let's have a progress check-in at 2 PM today to see how things are going. Keep up the good work, team! </div>
									<div className="widget-chat-time">10:10AM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-1.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-blue">Daniel</div>
									<div className="widget-chat-message"> Sounds good! See you at the meeting. </div>
									<div className="widget-chat-time">10:30AM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-2.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-indigo">Mark</div>
									<div className="widget-chat-message"> Looking forward to it. We'll have everything ready. </div>
									<div className="widget-chat-time">10:50AM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-3.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-red">Alexander</div>
									<div className="widget-chat-message"> Count me in. I'll be prepared with the financial figures. </div>
									<div className="widget-chat-time">11:15AM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media end">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-13.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-orange"></div>
									<div className="widget-chat-message"> Excellent teamwork, everyone! We're making great progress. </div>
									<div className="widget-chat-time">11:45AM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-1.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-blue">Daniel</div>
									<div className="widget-chat-message"> Thank you! It's a team effort. </div>
									<div className="widget-chat-time">12:20PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-2.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-indigo">Mark</div>
									<div className="widget-chat-message"> Absolutely, we've got a strong team. </div>
									<div className="widget-chat-time">01:05PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-3.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-red">Alexander</div>
									<div className="widget-chat-message"> Agreed, we're all working towards the same goal. </div>
									<div className="widget-chat-time">02:00PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media end">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-13.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-message"> That's the spirit! Let's keep the communication flowing. If you have any questions or face any challenges, don't hesitate to reach out. </div>
									<div className="widget-chat-time">02:45PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-1.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-blue">Daniel</div>
									<div className="widget-chat-message"> Will do, Manager. </div>
									<div className="widget-chat-time">03:10PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-2.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-indigo">Mark</div>
									<div className="widget-chat-message"> Understood, we'll collaborate closely. </div>
									<div className="widget-chat-time">03:35PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-3.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-red">Alexander</div>
									<div className="widget-chat-message"> Thanks for the support, Manager. </div>
									<div className="widget-chat-time">04:00PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media end">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-13.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-message"> Alexander, can you also provide an update on the budget allocation? </div>
									<div className="widget-chat-time">04:25PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-3.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-red">Alexander</div>
									<div className="widget-chat-message"> Sure, I'll get that information for you by the end of the day. </div>
									<div className="widget-chat-time">04:50PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media end">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-13.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-message"> Perfect. And Mark, how's the visual design coming along? </div>
									<div className="widget-chat-time">05:15PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-2.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-indigo">Mark</div>
									<div className="widget-chat-message"> It's going smoothly. I'll share the mockups with you later today. </div>
									<div className="widget-chat-time">05:40PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media end">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-13.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-message"> Sounds great, Mark. Looking forward to it. </div>
									<div className="widget-chat-time">06:05PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-1.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-blue">Daniel</div>
									<div className="widget-chat-message"> Should we discuss the presentation format for the report? </div>
									<div className="widget-chat-time">06:30AM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media end">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-13.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-message"> Good point, Daniel. Let's have a quick discussion on that during the meeting today. </div>
									<div className="widget-chat-time">07:00PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-3.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-red">Alexander</div>
									<div className="widget-chat-message"> I'll make sure the financial data is presented in a clear and concise manner. </div>
									<div className="widget-chat-time">07:25PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media end">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-13.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-message"> Thank you, Alexander. That will be essential for our stakeholders. </div>
									<div className="widget-chat-time">07:50PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-2.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-indigo">Mark</div>
									<div className="widget-chat-message"> Do we have all the necessary data and information for the report? </div>
									<div className="widget-chat-time">08:15PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-4.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-blue">Daniel</div>
									<div className="widget-chat-message"> I've collected most of it, but I'm waiting on a few figures from the sales team. </div>
									<div className="widget-chat-time">08:40PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-3.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-red">Alexander</div>
									<div className="widget-chat-message"> I'll follow up with them to expedite the process. </div>
									<div className="widget-chat-time">09:05PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media end">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-13.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-message"> Great teamwork, everyone. Keep up the good work. Our client will be impressed! </div>
									<div className="widget-chat-time">09:30PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-4.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-blue">Daniel</div>
									<div className="widget-chat-message"> We won't disappoint! </div>
									<div className="widget-chat-time">09:55PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-2.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-indigo">Mark</div>
									<div className="widget-chat-message"> Let's make it a stellar report! </div>
									<div className="widget-chat-time">10:20PM</div>
								</div>
							</div>
						</div>
						<div className="widget-chat-item with-media start">
							<div className="widget-chat-media">
								<img alt="" src="/assets/img/user/user-3.jpg" />
							</div>
							<div className="widget-chat-info">
								<div className="widget-chat-info-container">
									<div className="widget-chat-name text-red">Alexander</div>
									<div className="widget-chat-message"> Agreed, let's do our best! </div>
									<div className="widget-chat-time">10:45PM</div>
								</div>
							</div>
						</div>
					</PerfectScrollbar> */}
					<PerfectScrollbar className="widget-chat-body h-100">
						{
							dataConversation.length > 0 && dataConversation.map((data) => (
								<Chat data={data} key={uuidv4()} />
							))
						}
					</PerfectScrollbar>
					<div className="widget-chat-input">
						<div className="widget-chat-toolbar">
							<div className="widget-chat-toolbar-link cursor-pointer">
								<EmojiPickerComponent onEmojiSelect={handleEmojiSelect} />
							</div>
							<a href="#/" className="widget-chat-toolbar-link">
								<Icon className="iconify fs-26px" icon="solar:folder-with-files-outline" />
							</a>
							<a href="#/" className="widget-chat-toolbar-link">
								<Icon className="iconify fs-26px" icon="solar:scissors-square-outline" />
							</a>
							<a href="#/" className="widget-chat-toolbar-link">
								<Icon className="iconify fs-26px" icon="solar:chat-round-dots-outline" />
							</a>
							<a href="#/" className="widget-chat-toolbar-link ms-auto">
								<Icon className="iconify fs-26px" icon="solar:phone-calling-outline" />
							</a>
							<a href="#/" className="widget-chat-toolbar-link">
								<Icon className="iconify fs-26px" icon="solar:videocamera-record-outline" />
							</a>
						</div>
						<textarea className="form-control" value={textArea} onChange={(e) => setTextArea(e.target.value)}></textarea>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Conversations;