import React, { useEffect, useState } from "react";
import fetchAPI from "../../helpers/fetch";
import { urlAPIMultiAgent } from "../../helpers/urlAPI";
import { getToken } from "../../helpers/token";
import TableClient from "./table";
import Header from "../../ownComponents/table/header";
import Actions from "../../ownComponents/table/actions";
import Tabs from "../../ownComponents/table/tabs";
import FilterSearch from "../../ownComponents/table/filterSearch";
import Pagination from "../../ownComponents/table/pagination";
import Modal from "../../ownComponents/modal/modal";
import Alert, { addNotification } from "../../ownComponents/alerts/alerts"
import { handleErrorFormat } from "../../helpers/handleError";
import { Modal as ModalClient } from 'bootstrap';
import { getLocalStorageItem } from "../../helpers/localStorage";

function Clients() {
    const { suscriptionId } = JSON.parse(getLocalStorageItem("suscription"));

    const dataClientIni = {
        id: 0,
        name: "",
        phone: "",
        available: true
    }
    const token = getToken();


    const tableHeaders =
        [
            { header: "Id" },
            { header: "Nombre" },
            { header: "Celular" },
            { header: "Estatus" },
            { header: "Acciones" }
        ];
    const tableTabs = [
        "Disponible",
        "No disponible",
    ]
    const [dataClients, setDataClients] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = dataClients.slice(indexOfFirstEntry, indexOfLastEntry);
    const [filter, setFilter] = useState('');
    const [dataClientForm, setDataClientForm] = useState(dataClientIni);
    const [dataClientsFilter, setDataClientsFilter] = useState([]);
    const [totalEntries, setTotalEntries] = useState(0);
    const [activeTab, setActiveTab] = useState('Disponible');
    const [reloadData, setReloadData] = useState(false);
    const [modal, setModal] = useState();
    const [modalDelete, setModalDelete] = useState();


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab); // Cambiar la pestaña activa
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setDataClientForm((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleChangeAvailable = (e) => {
        setDataClientForm(dataClientForm => ({ ...dataClientForm, available: !dataClientForm.available }))
    }

    const handleGet = async (id) => {
        await ftFetchGet(id);
        modal.show();
    }


    const handleDelete = async (id) => {
        modalDelete.show();
        setDataClientForm({ ...dataClientForm, id })
    }

    const handleCloseModal = () => {
        modal.hide();
        setDataClientForm(dataClientIni);
    }

    const handleBtnHeader = () => {
        modal.show();
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const result = dataClientForm.id === 0 ? await ftFetchInsert() : await ftFetchEdit(dataClientForm.id);
            setReloadData(reloadData => !reloadData);
            handleCloseModal();
            addNotification({
                notificationTitle: "¡Exito!",
                notificationMessage: result.message,
                notificationPosition: 'top-center',
                notificationType: 'success'
            })
        } catch (error) {
            addNotification({
                notificationTitle: "Ha ocurrido un error",
                notificationMessage: handleErrorFormat(error),
                notificationPosition: 'top-center',
                notificationType: 'warning'
            });
        }

    }
    const ftFetchGet = async (id) => {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`
            }

            const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/client/${id}/get`, 'POST', null, headers);
            setDataClientForm(result.data);
        } catch (error) {
            addNotification({
                notificationTitle: "Ha ocurrido un error",
                notificationMessage: handleErrorFormat(error),
                notificationPosition: 'top-center',
                notificationType: 'warning'
            });
        }
    }

    const ftFetchDelete = async () => {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`
            }

            const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/client/${dataClientForm.id}/remove`, 'POST', null, headers);
            addNotification({
                notificationTitle: "¡Exito!",
                notificationMessage: result.message,
                notificationPosition: 'top-center',
                notificationType: 'success'
            });
            modalDelete.hide();
            setDataClientForm(dataClientIni);
        } catch (error) {
            addNotification({
                notificationTitle: "Ha ocurrido un error",
                notificationMessage: handleErrorFormat(error),
                notificationPosition: 'top-center',
                notificationType: 'warning'
            });
        }
    }

    const ftFetchEdit = async (id) => {
        const headers = {
            'Authorization': `Bearer ${token}`
        }
        const bodyFetch = {
            name: dataClientForm.name,
            phone: dataClientForm.phone,
            available: dataClientForm.available ? "true" : "false"
        }
        const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/client/${id}/update`, 'POST', bodyFetch, headers);
        return result;
    }

    const ftFetchInsert = async () => {
        const headers = {
            'Authorization': `Bearer ${token}`
        }
        const bodyFetch = {
            name: dataClientForm.name,
            phone: dataClientForm.phone,
            available: dataClientForm.available ? "true" : "false"
        }
        const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/client/create`, 'POST', bodyFetch, headers);
        return result;
    }



    useEffect(() => {
        setModal(new ModalClient(document.getElementById('client')));
        setModalDelete(new ModalClient(document.getElementById('clientDelete')));
        return () => {
            setModal();
            setModalDelete();
        }
    }, [])

    useEffect(() => {

        const fetchData = async () => {
            try {
                const headers = {
                    'Authorization': `Bearer ${token}`
                }
                const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/client/list`, 'POST', null, headers);
                setDataClients(result.data);
                setDataClientsFilter(result.data);
                setTotalEntries(result.data.length);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
        // return () => {

        // };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadData]);

    useEffect(() => {
        const filteredData = dataClientsFilter.filter(
            item => item.name !== null ? item.name.toLowerCase().includes(filter.toLowerCase()) : item);
        setDataClients(filteredData);

        const totalEntries = filteredData.length;
        setTotalEntries(totalEntries);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);
    const totalPages = Math.ceil(totalEntries / entriesPerPage);

    // Ajustar la página actual si es necesario
    useEffect(() => {
        if (currentPage > totalPages) {
            setCurrentPage(totalPages > 0 ? totalPages : 1);
        }
    }, [totalEntries, currentPage, totalPages]);


    const ftModalForm = () => {

        return <form onSubmit={handleSubmit} className="fs-13px">
            <div className="form-floating mb-15px">
                <input type="text" className="form-control h-45px fs-13px" placeholder="Nombre" id="name" name='name' required value={dataClientForm.name} onChange={(e) => handleChange(e)} pattern="^(?!\s*$).+" />
                <label htmlFor="name" className="d-flex align-items-center fs-13px text-gray-600">Nombre Completo</label>
            </div>
            <div className="form-floating mb-15px">
                <input type="text" className="form-control h-45px fs-13px" placeholder="Teléfono" id="phone" name='phone' required value={dataClientForm.phone} onChange={(e) => handleChange(e)} pattern="^(?!\s*$).+" />
                <label htmlFor="phone" className="d-flex align-items-center fs-13px text-gray-600">Teléfono</label>
            </div>
            <div className="form-check form-switch ms-auto mb-0">
                <input type="checkbox" className="form-check-input cursor-pointer" name="available" onChange={(e) => handleChangeAvailable(e)} id="available" checked={dataClientForm.available} value={dataClientForm.available} />
                <label className="form-check-label" htmlFor="available">Disponible</label>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-default" onClick={handleCloseModal}>Cancelar</button>
                <button type="submit" className="btn btn-theme">Guardar</button>
            </div>
        </form>
    }

    const ftModalDelete = () => {

        return <div className="d-flex justify-content-evenly">
            <button type="button" className="btn btn-default" onClick={() => modalDelete.hide()}>Cancelar</button>
            <button type="submit" className="btn btn-danger" onClick={async () => {
                await ftFetchDelete();
                setReloadData(reloadData => !reloadData);
            }}>Eliminar</button>
        </div>
    }

    return (
        <>
            <Modal body={ftModalForm()} header={`${dataClientForm.id === 0 ? 'Agregar' : 'Editar'} Cliente`} idModal="client" handleCloseModal={handleCloseModal} />
            <Modal body={ftModalDelete()} header={"¿Desea eliminar el cliente?"} idModal="clientDelete" handleCloseModal={() => modalDelete.hide()} />
            <Header btnText={"Agregar"} header={"Clientes"} handleButton={handleBtnHeader} />
            <Actions />
            <div className="card border-0">
                <Tabs tabs={tableTabs} activeTab={activeTab} onTabChange={handleTabChange} />
                <div className="tab-content p-3">
                    <div className="tab-pane fade show active" id="allTab">
                        <FilterSearch setFilter={setFilter} />
                        <div className="table-responsive mb-3">
                            <TableClient headers={tableHeaders} body={currentEntries} handleGet={handleGet} handleDelete={handleDelete} />
                        </div>
                        <Pagination currentPage={currentPage} entriesPerPage={entriesPerPage} onPageChange={handlePageChange} totalEntries={totalEntries} setEntriesPerPage={setEntriesPerPage} totalPages={totalPages} />
                    </div>
                </div>
            </div>

            <Alert />
        </>
    );
}

export default Clients;