import React, { useEffect, useState } from "react";
import fetchAPI from "../../helpers/fetch";
import { urlAPIMultiAgent } from "../../helpers/urlAPI";
import { getToken } from "../../helpers/token";
import Header from "../../ownComponents/table/header";
import Actions from "../../ownComponents/table/actions";
import Tabs from "../../ownComponents/table/tabs";
import FilterSearch from "../../ownComponents/table/filterSearch";
import Pagination from "../../ownComponents/table/pagination";
import Modal from "../../ownComponents/modal/modal";
import Alert, { addNotification } from "../../ownComponents/alerts/alerts"
import { handleErrorFormat } from "../../helpers/handleError";
import { Modal as ModalAdmin } from 'bootstrap';
import ReactSelect from "react-select";
import TableAdmins from "./table";
import { getLocalStorageItem } from "../../helpers/localStorage";

function Administrators() {
    const { suscriptionId } = JSON.parse(getLocalStorageItem("suscription"));

    const dataAdminIni = {
        id: 0,
        name: "",
        phone: "",
        available: "",
        userId: "",
    }
    const token = getToken();


    const tableHeaders =
        [
            { header: "Id" },
            { header: "Nombre" },
            { header: "Propietario" },
            { header: "Teléfono" },
            { header: "Estatus" },
            { header: "Acciones" }
        ];
    const tableTabs = [

    ]
    const [dataAdmins, setDataAdmins] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = dataAdmins.slice(indexOfFirstEntry, indexOfLastEntry);
    const [filter, setFilter] = useState('');
    const [dataAdminForm, setDataAdminForm] = useState(dataAdminIni);
    const [dataAdminsFilter, setDataAdminsFilter] = useState([]);
    const [totalEntries, setTotalEntries] = useState(0);
    const [activeTab, setActiveTab] = useState('Disponible');
    const [reloadData, setReloadData] = useState(false);
    const [modal, setModal] = useState();
    const [modalDelete, setModalDelete] = useState();
    const [dataUsers, setDataUsers] = useState([]);


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab); // Cambiar la pestaña activa
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setDataAdminForm((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleChangeAvailable = () => {
        setDataAdminForm(dataAdminForm => ({ ...dataAdminForm, available: !dataAdminForm.available }))
    }

    const handleGet = async (id) => {
        await ftFetchGet(id);
        modal.show();
    }


    const handleDelete = async (id) => {
        modalDelete.show();
        setDataAdminForm({ ...dataAdminForm, id })
    }

    const handleCloseModal = () => {
        modal.hide();
        setDataAdminForm(dataAdminIni);
    }

    const handleBtnHeader = () => {
        modal.show();
    }

    const handleReactSelect = (e) => {
        const { value, label } = e;
        setDataAdminForm({ ...dataAdminForm, userId: value, userName: label })
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const result = dataAdminForm.id === 0 ? await ftFetchInsert() : await ftFetchEdit(dataAdminForm.id);
            setReloadData(reloadData => !reloadData);
            handleCloseModal();
            addNotification({
                notificationTitle: "¡Exito!",
                notificationMessage: result.message,
                notificationPosition: 'top-center',
                notificationType: 'success'
            })
        } catch (error) {
            addNotification({
                notificationTitle: "Ha ocurrido un error",
                notificationMessage: handleErrorFormat(error),
                notificationPosition: 'top-center',
                notificationType: 'warning'
            });
        }

    }
    const ftFetchGet = async (id) => {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`
            }

            const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/admin/${id}/get`, 'POST', null, headers);
            setDataAdminForm(result.data);
        } catch (error) {
            addNotification({
                notificationTitle: "Ha ocurrido un error",
                notificationMessage: handleErrorFormat(error),
                notificationPosition: 'top-center',
                notificationType: 'warning'
            });
        }
    }

    const ftFetchDelete = async () => {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`
            }

            const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/admin/${dataAdminForm.id}/remove`, 'POST', null, headers);
            addNotification({
                notificationTitle: "¡Exito!",
                notificationMessage: result.message,
                notificationPosition: 'top-center',
                notificationType: 'success'
            });
            modalDelete.hide();
            setDataAdminForm(dataAdminIni);
        } catch (error) {
            addNotification({
                notificationTitle: "Ha ocurrido un error",
                notificationMessage: handleErrorFormat(error),
                notificationPosition: 'top-center',
                notificationType: 'warning'
            });
        }
    }

    const ftFetchEdit = async (id) => {
        const headers = {
            'Authorization': `Bearer ${token}`
        }
        const bodyFetch = {
            name: dataAdminForm.name,
            phone: dataAdminForm.phone,
            available: dataAdminForm.available ? "true" : "false",
            userId: dataAdminForm.userId
        }
        const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/admin/${id}/update`, 'POST', bodyFetch, headers);
        return result;
    }

    const ftFetchInsert = async () => {
        const headers = {
            'Authorization': `Bearer ${token}`
        }
        const bodyFetch = {
            name: dataAdminForm.name,
            phone: dataAdminForm.phone,
            available: dataAdminForm.available ? "true" : "false",
            userId: dataAdminForm.userId
        }
        const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/admin/create`, 'POST', bodyFetch, headers);
        return result;
    }




    useEffect(() => {
        setModal(new ModalAdmin(document.getElementById('admin')));
        setModalDelete(new ModalAdmin(document.getElementById('adminDelete')));
        return () => {
            setModal();
            setModalDelete();
        }
    }, [])

    useEffect(() => {

        const fetchDataUser = async () => {
            try {
                const headers = {
                    'Authorization': `Bearer ${token}`
                }
                const result = await fetchAPI(`${urlAPIMultiAgent}/user/list`, 'POST', null, headers);
                const auxDataUser = result.data.map(({ id: value, name: label }) => ({ value, label }))
                auxDataUser.unshift({ value: "0", label: "Selecciona un propietario" })
                setDataUsers(auxDataUser);
                setDataAdminForm({ ...dataAdminForm, userId: auxDataUser[0].value, userName: auxDataUser[0].label })
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchDataUser();
        // return () => {

        // };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        const fetchAdministrators = async () => {
            try {
                const headers = {
                    'Authorization': `Bearer ${token}`
                }
                const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/admin/list`, 'POST', null, headers);
                setDataAdmins(result.data);
                setDataAdminsFilter(result.data);
                setTotalEntries(result.data.length);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchAdministrators();
        // return () => {

        // };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadData]);

    useEffect(() => {
        const filteredData = dataAdminsFilter.filter(
            item => item.name !== null ? item.name.toLowerCase().includes(filter.toLowerCase()) : item);
        setDataAdmins(filteredData);

        const totalEntries = filteredData.length;
        setTotalEntries(totalEntries);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);
    const totalPages = Math.ceil(totalEntries / entriesPerPage);

    // Ajustar la página actual si es necesario
    useEffect(() => {
        if (currentPage > totalPages) {
            setCurrentPage(totalPages > 0 ? totalPages : 1);
        }
    }, [totalEntries, currentPage, totalPages]);

    const ftModalForm = () => {

        return <form onSubmit={handleSubmit} className="fs-13px">
            <div className="form-floating mb-15px">
                <input type="text" className="form-control h-45px fs-13px" placeholder="Nombre completo" id="name" name='name' required value={dataAdminForm.name} onChange={(e) => handleChange(e)} pattern="^(?!\s*$).+" />
                <label htmlFor="name" className="d-flex align-items-center fs-13px text-gray-600">Nombre Completo</label>
            </div>
            <div className="form-floating mb-15px">
                <input type="text" className="form-control h-45px fs-13px" placeholder="Teléfono" id="phone" name='phone' required value={dataAdminForm.phone} onChange={(e) => handleChange(e)} pattern="^(?!\s*$).+" />
                <label htmlFor="phone" className="d-flex align-items-center fs-13px text-gray-600">Teléfono</label>
            </div>
            <div className="form-group mb-15px">
                <label htmlFor="url" className="d-flex align-items-center fs-13px text-gray-600 pb-2">¿Asociado/Invitado?</label>
                <ReactSelect options={dataUsers} onChange={handleReactSelect} value={{ label: dataAdminForm.userName }}
                    isOptionDisabled={(option) => option.value === "0"}
                    classNamePrefix="react-select" />
            </div>
            <div className="form-check form-switch ms-auto mb-0">
                <input type="checkbox" className="form-check-input cursor-pointer" name="available" onChange={(e) => handleChangeAvailable(e)} id="available" checked={dataAdminForm.available} value={dataAdminForm.available} />
                <label className="form-check-label" htmlFor="available">Disponible</label>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-default" onClick={handleCloseModal}>Cancelar</button>
                <button type="submit" className="btn btn-theme">Guardar</button>
            </div>
        </form>
    }

    const ftModalDelete = () => {

        return <div className="d-flex justify-content-evenly">
            <button type="button" className="btn btn-default" onClick={() => modalDelete.hide()}>Cancelar</button>
            <button type="submit" className="btn btn-danger" onClick={async () => {
                await ftFetchDelete();
                setReloadData(reloadData => !reloadData);
            }}>Eliminar</button>
        </div>
    }

    return (
        <>
            <Modal body={ftModalForm()} header={`${dataAdminForm.id === 0 ? 'Agregar' : 'Editar'} Administrador`} idModal="admin" handleCloseModal={handleCloseModal} />
            <Modal body={ftModalDelete()} header={"¿Desea eliminar el administrador?"} idModal="adminDelete" handleCloseModal={() => modalDelete.hide()} />
            <Header btnText={"Agregar"} header={"Administradores"} handleButton={handleBtnHeader} />
            <Actions />
            <div className="card border-0">
                <Tabs tabs={tableTabs} activeTab={activeTab} onTabChange={handleTabChange} />
                <div className="tab-content p-3">
                    <div className="tab-pane fade show active" id="allTab">
                        <FilterSearch setFilter={setFilter} />
                        <div className="table-responsive mb-3">
                            <TableAdmins headers={tableHeaders} body={currentEntries} handleGet={handleGet} handleDelete={handleDelete} />
                        </div>
                        <Pagination currentPage={currentPage} entriesPerPage={entriesPerPage} onPageChange={handlePageChange} totalEntries={totalEntries} setEntriesPerPage={setEntriesPerPage} totalPages={totalPages} />
                    </div>
                </div>
            </div>

            <Alert />
        </>
    );
}

export default Administrators;