import React from 'react';

function MessageContainer({ conversation, isActive = false, ftClick = () => { } }) {

    const { client, startDate } = conversation;
    const activeStyle = isActive ? "active" : "";

    // id, agent, client, startDate, endDate, active, waitingForAgent, waitingForFile, startedByAgent, finishedByAgent
    return (
        <div className="messenger-chat-list" >
            <div className={`messenger-chat-item ${activeStyle}`}>
                <span className="messenger-chat-link cursor-pointer" onClick={ftClick}>
                    <div className="messenger-chat-media">
                        <img alt={client} src={"https://cdn-icons-png.flaticon.com/512/17/17004.png"} />
                    </div>
                    <div className="messenger-chat-content">
                        <div className="messenger-chat-title">
                            <div>{client}</div>
                            <div className="messenger-chat-time">{startDate}</div>
                        </div>
                        <div className="messenger-chat-desc"> {"Aquí debe ir el"}</div>
                    </div>
                </span>
            </div>
        </div>
    )
}

export default MessageContainer;

