import React from "react";

const Actions = () => {
    return (
        <div className="mb-3 d-md-flex fw-bold">
            <div className="mt-md-0 mt-2"><a href="#/" className="text-dark text-decoration-none"><i className="fa fa-download fa-fw me-1 text-dark text-opacity-50"></i> Export</a></div>
            <div className="ms-md-4 mt-md-0 mt-2 dropdown-toggle">
                <a href="#/" data-bs-toggle="dropdown" className="text-dark text-decoration-none">More Actions <b className="caret"></b></a>
                <div className="dropdown-menu">
                    <a href="#/" className="dropdown-item">Action</a>
                    <a href="#/" className="dropdown-item">Another action</a>
                    <a href="#/" className="dropdown-item">Something else here</a>
                    <div role="separator" className="dropdown-divider"></div>
                    <a href="#/" className="dropdown-item">Separated link</a>
                </div>
            </div>
        </div>
    )
}

export default Actions;