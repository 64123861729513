import React, { useEffect, useContext, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import fetchAPI from '../../helpers/fetch.js';
import { urlAPIMultiAgent } from '../../helpers/urlAPI.js';
import { getLocalStorageItem, setLocalStorageItem } from '../../helpers/localStorage.js';
import Alert, { addNotification } from '../../ownComponents/alerts/alerts.js';
import { handleErrorFormat } from '../../helpers/handleError.js';

function LoginV3() {
  const context = useContext(AppSettings);
  const [redirect, setRedirect] = useState(false);
  const [userLogin, setUserLogin] = useState({ emailAddress: "", password: "" });

  useEffect(() => {
    const token = getLocalStorageItem("token");
    if (token) {
      setRedirect(true);
    }
  }, []);

  useEffect(() => {
    context.handleSetAppSidebarNone(true);
    context.handleSetAppHeaderNone(true);
    context.handleSetAppContentClass('p-0');

    return () => {
      context.handleSetAppSidebarNone(false);
      context.handleSetAppHeaderNone(false);
      context.handleSetAppContentClass('');
    };
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const result = await ftFetchLogin()
      if (result?.data) {
        setLocalStorageItem("token", JSON.stringify({
          jwt: result.data.jwt
        }));
        setLocalStorageItem("usr", JSON.stringify({ userId: result.data.id }))
        setRedirect(true);
      }
      else {
        addNotification({
          notificationType: "warning", notificationTitle: "Alerta", notificationMessage: result.message, notificationPosition: "top-center"
        })
      }
    } catch (error) {
      addNotification({
        notificationType: "danger", notificationTitle: "Error", notificationMessage: handleErrorFormat(error), notificationPosition: "top-center"
      })
    }

  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserLogin((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const ftFetchLogin = async () => {
    const bodyFetch = {
      username: userLogin.emailAddress,
      password: userLogin.password
    }
    const result = await fetchAPI(`${urlAPIMultiAgent}/auth/login`, 'POST', bodyFetch);
    return result;
  }

  if (redirect) {
    return <Navigate to='/configuracion' replace={true} />;
  }

  return (
    <div className="login login-with-news-feed">
      <Alert />
      <div className="news-feed">
        <div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/login-bg-11.jpg)' }}></div>
        <div className="news-caption">
          <h4 className="caption-title">!Incrementa tus ventas!</h4>
          <p>
            Aumentando la capacidad y calidad de atención a clientes con nuestra solución Multiagente. Genera una mayor satisfacción y concreta más ventas!
          </p>
        </div>
      </div>
      <div className="login-container">
        <div className="login-header mb-30px">
          <div className="brand">
            <div className="d-flex align-items-center">
              <span className="logo"></span>
              <b>Color</b> Admin
            </div>
            <small>Bootstrap 5 Responsive Admin Template</small>
          </div>
          <div className="icon">
            <i className="fa fa-sign-in-alt"></i>
          </div>
        </div>
        <div className="login-content">
          <form onSubmit={handleSubmit} className="fs-13px">
            <div className="form-floating mb-15px">
              <input type="text" className="form-control h-45px fs-13px" placeholder="Email Address" id="emailAddress" name='emailAddress' required value={userLogin.emailAddress} onChange={(e) => handleChange(e)} pattern="[^ ]+" title="Campo requerido" />
              <label htmlFor="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Email Address</label>
            </div>
            <div className="form-floating mb-15px">
              <input type="password" className="form-control h-45px fs-13px" placeholder="Password" id="password" name='password' required value={userLogin.password} onChange={(e) => handleChange(e)} />
              <label htmlFor="password" className="d-flex align-items-center fs-13px text-gray-600">Password</label>
            </div>
            {/* <div className="form-check mb-30px">
              <input className="form-check-input" type="checkbox" value="1" id="rememberMe" />
              <label className="form-check-label" htmlFor="rememberMe">
                Remember Me
              </label>
            </div> */}
            <div className="mb-15px">
              <button type="submit" className="btn btn-theme d-block h-45px w-100 btn-lg fs-14px">Ingresar</button>
            </div>
            <div className="mb-40px pb-40px text-body">
              ¿Aun no estas registrado? Haz click  <Link to="/user/register-v3" className="text-primary">aquí</Link> para registrarte.
            </div>
            <hr className="bg-gray-600 opacity-2" />
            <div className="text-gray-600 text-center text-gray-500-darker mb-0">
              © App Multiagente Todos los derechos reservados {new Date().getFullYear()}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginV3;