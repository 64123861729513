import React from 'react';

function Chat({ data }) {
    const { id, agent, client, content, method, delivered, deliverFailed, deliveredDate } = data;

    return (
        <div className={`widget-chat-item with-media ${client ? 'start' : 'end'}`}>
            <div className="widget-chat-media">
                <img alt="" src={"https://cdn-icons-png.flaticon.com/512/17/17004.png"} />
            </div>
            <div className="widget-chat-info">
                <div className="widget-chat-info-container">
                    <div className="widget-chat-message"> {content} </div>
                    <div className="widget-chat-time">{deliveredDate}</div>
                </div>
            </div>
        </div>
    )
}

export default Chat;

