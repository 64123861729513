import React, { useEffect, useState, useContext } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import fetchAPI from '../../helpers/fetch.js';
import { urlAPIMultiAgent } from '../../helpers/urlAPI.js';
import Alert, { addNotification } from '../../ownComponents/alerts/alerts.js';

function RegisterV3() {
  const context = useContext(AppSettings);

  const [userRegister, setUserRegister] = useState({
    username: "",
    password: "",
    name: "",
    email: "",
    phone: ""
  });

  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    context.handleSetAppSidebarNone(true);
    context.handleSetAppHeaderNone(true);
    context.handleSetAppContentClass('p-0');

    return () => {
      context.handleSetAppSidebarNone(false);
      context.handleSetAppHeaderNone(false);
      context.handleSetAppContentClass('');
    };
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isTermsChecked) {
      addNotification({
        notificationTitle: "Alerta",
        notificationMessage: "Debe aceptar los terminos y condiciones.",
        notificationPosition: 'top-center',
        notificationType: 'warning'
      });
      return;
    }
    const result = await ftFetchSignUp();
    addNotification({
      notificationTitle: "¡Exito!",
      notificationMessage: result.message,
      notificationPosition: 'top-center',
      notificationType: 'success'
    })
    if (result.data) {
      setRedirect(true);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserRegister((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const ftFetchSignUp = async () => {
    const bodyFetch = {
      username: userRegister.username,
      password: userRegister.password,
      name: userRegister.name,
      email: userRegister.email,
      phone: userRegister.phone
    }
    const result = await fetchAPI(`${urlAPIMultiAgent}/auth/signup`, 'POST', bodyFetch);
    return result;
  }

  if (redirect) {
    return <Navigate to='/user/login-v3' />;
  }

  return (
    <>
      <div className="register register-with-news-feed">
        <div className="news-feed">
          <div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/login-bg-9.jpg)' }}></div>
          <div className="news-caption">
            <h4 className="caption-title">!Incrementa tus ventas!</h4>
            <p>
              Aumentando la capacidad y calidad de atención a clientes con nuestra solución Multiagente. Genera una mayor satisfacción y concreta más ventas!
            </p>
          </div>
        </div>
        <div className="register-container">
          <div className="register-header mb-25px h1">
            <div className="mb-1">Registro</div>
            <small className="d-block fs-15px lh-16">Crea una cuenta gratuita en App Multiagente</small>
          </div>
          <div className="register-content">
            <form onSubmit={handleSubmit} className="fs-13px">
              <div className="mb-3">
                <label className="mb-2">Nombre <span className="text-danger">*</span></label>
                <div className="row gx-3">
                  <div className="col-md-12 mb-12 mb-md-0">
                    <input type="text" className="form-control fs-13px" placeholder="Nombre" name='name' onChange={(e) => handleChange(e)} value={userRegister.name} required />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label className="mb-2">Usuario <span className="text-danger">*</span></label>
                <div className="row gx-3">
                  <div className="col-md-12 mb-12 mb-md-0">
                    <input type="text" className="form-control fs-13px" placeholder="Usuario" name='username' onChange={(e) => handleChange(e)} value={userRegister.username} required />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label className="mb-2">Correo <span className="text-danger">*</span></label>
                <input type="email" className="form-control fs-13px" placeholder="Correo" name='email' onChange={(e) => handleChange(e)} value={userRegister.email} required />
              </div>
              <div className="mb-3">
                <label className="mb-2">Teléfono <span className="text-danger">*</span></label>
                <input type="text" className="form-control fs-13px" placeholder="Teléfono" name='phone' onChange={(e) => handleChange(e)} value={userRegister.phone} required />
              </div>

              <div className="mb-4">
                <label className="mb-2">Contraseña <span className="text-danger">*</span></label>
                <input type="password" className="form-control fs-13px" placeholder="Contraseña" name='password' onChange={(e) => handleChange(e)} value={userRegister.password} required />
              </div>

              <div className="form-check mb-4">
                <input className="form-check-input" type="checkbox" value={isTermsChecked} id="agreementCheckbox" onChange={(e) => {
                  setIsTermsChecked(e.target.checked);
                }} />
                <label className="form-check-label">
                  He leído y acepto los  <Link to="/terminos">términos y condiciones </Link>  y las <Link to="/politicas">políticas de privacidad. </Link>
                </label>
              </div>
              <div className="mb-4">
                <button type="submit" className="btn btn-theme d-block w-100 btn-lg h-45px fs-13px">Registrarse</button>
              </div>
              <div className="mb-4 pb-5">
                ¿Ya estas registrado? Haz click  <Link to="/user/login-v3">aquí</Link>  para ingresar.
              </div>
              <hr className="bg-gray-600 opacity-2" />
              <p className="text-center text-gray-600">
                © App Multiagente Todos los derechos reservados {new Date().getFullYear()}
              </p>
            </form>
          </div>
        </div>
      </div>
      <Alert />
    </>
  );
}

export default RegisterV3;