import React from "react";
import { v4 as uuidv4 } from 'uuid';
import StatusBadge from "../../ownComponents/statusBadge";

const TableAgent = ({ headers, body, handleGet, handleDelete }) => {

    return (
        <table className="table table-hover table-panel text-nowrap align-middle mb-0">
            <thead>
                <tr>
                    {
                        (Array.isArray(headers) && headers.length > 0) &&
                        headers.map(({ header }) => (
                            <th key={uuidv4()}>{header}</th>
                        ))}
                </tr>
            </thead>
            <tbody>
                {
                    (Array.isArray(body) && body.length > 0) &&
                    body.map((row) => (
                        <tr key={uuidv4()}>
                            <td className="w-10px align-middle">
                                {row.id}
                            </td>
                            <td className="w-10px align-middle">
                                {row.name}
                            </td>
                            <td className="w-10px align-middle">
                                {row.phone}
                            </td>
                            <td className="w-10px align-middle">
                                {row.available}
                                <StatusBadge status={`${row.available ? "success" : "gray"}`} text={`${row.available ? "Disponible" : "No disponible"}`} />
                            </td>
                            <td className="w-10px align-middle">
                                <div className="btn-group me-1 mb-1 ">
                                    <button className="btn btn-primary" data-bs-toggle="dropdown"><i className="fa fa-cog"></i></button>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <button className="dropdown-item" onClick={() => handleGet(row.id)}>
                                            <i className="fas fa-pencil-alt fa-fw pe-4"></i>
                                            Editar
                                        </button>
                                        <div className="dropdown-divider"></div>
                                        <button className="dropdown-item" onClick={() => handleDelete(row.id)}>
                                            <i className="fas fa-trash-can fa-fw pe-4"></i>
                                            Eliminar
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    )
}

export default TableAgent;