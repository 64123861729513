import React from 'react';

const Pagination = ({ totalEntries, entriesPerPage, currentPage, onPageChange, setEntriesPerPage, totalPages }) => {


    const getPaginationItems = () => {
        const paginationItems = [];

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                paginationItems.push(i);
            }
        } else {
            paginationItems.push('Previous');

            paginationItems.push(1);


            if (currentPage > 3) {
                paginationItems.push('...');
            }

            const startPage = Math.max(2, currentPage - 1);
            const endPage = Math.min(totalPages - 1, currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                paginationItems.push(i);
            }

            if (currentPage < totalPages - 2) {
                paginationItems.push('...');
            }

            paginationItems.push(totalPages);

            paginationItems.push('Next');
        }

        return paginationItems;
    };

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        onPageChange(page);
    };


    const handleTotalPerPage = (e) => {
        const { value } = e.target;
        setEntriesPerPage(value);

        if (currentPage > Math.ceil(totalEntries / value)) {
            onPageChange(1);
        }
    };


    return (
        <div className="d-md-flex align-items-center">
            <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                Mostrando {((currentPage - 1) * entriesPerPage) + 1} - {Math.min(currentPage * entriesPerPage, totalEntries)} de {totalEntries} registros.
                <select name='totalPerPage' onChange={handleTotalPerPage}>
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                </select>
            </div>
            <ul className="pagination mb-0 justify-content-center">
                {
                    getPaginationItems().map((item, index) => (
                        <li key={index} className={`page-item ${item === currentPage ? 'active' : ''}`}>
                            {typeof item === 'number' ? (
                                <a
                                    href="#/"
                                    className="page-link"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handlePageChange(item)}
                                >
                                    {item}
                                </a>
                            ) : (
                                <span style={{ cursor: 'pointer' }}
                                    className="page-link" onClick={() => item === 'Previous' ? handlePageChange(currentPage - 1) : handlePageChange(currentPage + 1)}>
                                    {item}
                                </span>
                            )}
                        </li>
                    ))
                }
            </ul>
        </div>
    );
};

export default Pagination;

