import EmojiPicker from 'emoji-picker-react';
import { useState } from 'react';
import "./index.css";
import { Icon } from '@iconify/react/dist/iconify.js';
function EmojiPickerComponent({ onEmojiSelect }) {
    const [showPicker, setShowPicker] = useState(false);

    const handleEmojiClick = (emojiObject) => {
        onEmojiSelect(emojiObject);
        setShowPicker(false);
    };

    const handleOpen = () => {
        setShowPicker(true);
    }

    return (
        <div className="" onClick={handleOpen}>
            <Icon className="iconify fs-26px" icon="solar:smile-circle-outline" />
            {showPicker &&
                <div className='emoji-picker'>
                    <EmojiPicker onEmojiClick={handleEmojiClick} lazyLoadEmojis={true} />
                </div>
            }
        </div>
    );
}

export default EmojiPickerComponent