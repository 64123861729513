import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import App from './../app.jsx';
// import LoginV1 from './../pages/user/login-v1.js';
// import LoginV2 from './../pages/user/login-v2.js';
import LoginV3 from './../pages/user/login-v3.js';
import RegisterV3 from './../pages/user/register-v3.js';
import HelperCSS from './../pages/helper/helper-css.js';
import ExtraError from './../pages/extra/extra-error.js'
import ExtraSettingsPage from '../pages/extra/extra-settings-page.js';
import ExtraFileManager from './../pages/extra/extra-file-manager.js';
import ExtraPricingPage from './../pages/extra/extra-pricing-page.js';
import PrivateRoute from '../ownComponents/privateRoute.js';
import Agents from '../ownPages/agents/index.js';
import Clients from '../ownPages/clients/index.js';
import Users from '../ownPages/users/index.js';
import Conversations from '../ownPages/conversations/conversations.js';
import Suscriptions from '../ownPages/suscriptions/index.js';
import Administrators from '../ownPages/admins/index.js';

const AppRoute = [
	{
		path: '*',
		element: <App />,
		children: [
			{
				path: '',
				element: <Navigate to='/configuracion' />
			},
			{
				path: 'user',
				element:
					<Outlet />,
				children: [
					// { path: 'login-v1', element: <LoginV1 /> },
					// { path: 'login-v2', element: <LoginV2 /> },
					{ path: 'login-v3', element: <LoginV3 /> },
					{ path: 'register-v3', element: <RegisterV3 /> },
					{ path: '*', element: <ExtraError /> }
				]
			},
			{
				path: 'configuracion',
				element:
					<PrivateRoute>
						<Outlet />
					</PrivateRoute>,
				children: [
					{ path: '', element: <ExtraSettingsPage /> },
					{ path: '*', element: <ExtraError /> }
				]
			},
			{
				path: 'catalogo',
				element:
					<PrivateRoute>
						<Outlet />
					</PrivateRoute>,
				children: [
					{ path: 'agentes', element: <Agents /> },
					{ path: 'clientes', element: <Clients /> },
					{ path: 'usuarios', element: <Users /> },
					{ path: 'suscripciones', element: <Suscriptions /> },
					{ path: 'administradores', element: <Administrators /> },
					{ path: 'archivos', element: <ExtraFileManager /> },
					{ path: '*', element: <ExtraError /> }
				]
			},
			{
				path: 'conversaciones',
				element:
					<PrivateRoute>
						<Conversations />
					</PrivateRoute>
			},
			{
				path: 'Precios',
				element:
					<PrivateRoute>
						<ExtraPricingPage />
					</PrivateRoute>
			},
			{
				path: 'helper/css',
				element:
					<PrivateRoute>
						<HelperCSS />
					</PrivateRoute>
			},
			{ path: '*', element: <ExtraError /> }
		]
	},
];


export default AppRoute;